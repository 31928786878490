
a {
  text-decoration: none
}

.accordion-body {
  padding: 0 !important;
}

.mail-button {
  position:absolute;
  z-index: 10000;
  bottom: 0px;
  //width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid white;;
  z-index: 11;
  border-radius: 0px;
  font-size: 18px;
}

